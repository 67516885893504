// Core
import {
  graphql,
  Link,
} from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import get from "lodash/get";
import React from "react";
import styles from "./styles.module.css";
// Components
import {
  Breadbrumbs,
  RichText,
  ShareButtons,
} from "../../components/";
import Layout from "../../layout/";

class ArticleTemplate extends React.Component {
  handleCopyLink(event) {
    // The button element
    const button = event.currentTarget;

    // Original inner HTML of button
    const original_html = event.currentTarget.innerHTML;

    // Change button text to reflect action
    button.innerHTML = "Link copied!";

    // Copy URL to clipboard
    const dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = `https://${window.location.host}${window.location.pathname}?ref=share-button`;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);

    // Return button text to original
    window.setTimeout(() => {
      button.innerHTML = original_html;
    }, 1500);
  }

  render() {
    const article = get(this.props, "data.contentfulJournalArticle");

    return (
      <Layout
        seo={{
          article: article,
          description: article.description.description,
          image: article.image.fixed.src,
          pathname: `journal/${article.slug}`,
          title: article.title,
        }}
      >
        <article className="container pt100">
          <Breadbrumbs
            breadcrumbs={[
              {
                label: "Home",
                url: "/",
              },
              {
                label: "Journal",
                url: "/journal",
              },
              {
                label: article.title,
              },
            ]}
          />

          <header className="mb20">
            <h1 className="h2 mb20 mt0">
              {article.title}
            </h1>

            <div className="flex jcb rfdc">
              <div>
                <span className="block small text-primary">
                  <span className="color-secondary">
                    by&nbsp;
                  </span>

                  <Link
                    className="link"
                    to={`/journal/authors/${article.author.slug}`}
                  >
                    {article.author.name}
                  </Link>

                  <span className="color-secondary mx05 opacity-05 xsmall">
                    Ω
                  </span>

                  <Link
                    className="link"
                    to={`/journal/topics/${article.topic.slug}`}
                  >
                    {article.topic.name}
                  </Link>
                </span>

                <span className="block color-secondary small">
                  <time dateTime={article.publishDatePlain}>
                    {article.publishDate}
                  </time>

                  <span className="mx05 opacity-05 xsmall">
                    Ω
                  </span>

                  {article.readTime} min read
                </span>
              </div>

              <div className="aie flex rmt20">
                <ShareButtons
                  image={article.image.fixed.src}
                  pathname={`/journal/${article.slug}`}
                  summary={article.description.description}
                  title={article.title}
                />
              </div>
            </div>
          </header>

          <div>
            <GatsbyImage
              alt={article.title}
              image={article.image.gatsbyImageData}
            />
          </div>

          <div className="mt50 rte">
            <RichText
              json={JSON.parse(article.body.raw)}
              references={article.body.references}
            />
          </div>

          <div className={styles.footer}>
            <ShareButtons
              image={article.image.fixed.src}
              pathname={`/journal/${article.slug}`}
              summary={article.description.description}
              title={article.title}
            />

            <button
              className="aic button button--outlined flex rmt20"
              onClick={this.handleCopyLink}
              type="button"
            >
              <svg
                aria-hidden="true"
                fill="none"
                height="15"
                role="img"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                width="15"
              >
                <use href="/images/feather-icons.svg#link-2" xlinkHref="/images/feather-icons.svg#link-2" />
              </svg>
              &nbsp;
              Copy link
            </button>
          </div>
        </article>
      </Layout>
    );
  }
}

export default ArticleTemplate;

export const pageQuery = graphql`
  query ArticleTemplateQuery($slug: String!) {
    contentfulJournalArticle(slug: { eq: $slug }) {
      title
      slug
      description {
        description
      }
      image {
        fixed(height: 628, width: 1200) {
          src
        }
        gatsbyImageData(aspectRatio: 2, layout: FULL_WIDTH, placeholder: BLURRED)
      }
      topic {
        name
        slug
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            title
          }
        }
      }
      author {
        name
        slug
      }
      publishDate(formatString: "MMMM Do, YYYY")
      publishDatePlain: publishDate
      readTime
    }
  }
`;
